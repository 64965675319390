import { HomepageCbRolexPosBanner } from '@lib/kontent/models/HomepageCbRolexPosBanner'
import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import { Box } from '@components/gassan-ui/Layout/Box'
import Script from 'next/script'

type Props = HomepageCbRolexPosBanner

const HomepageRolexPosBanner: FC<Props> = () => {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <Box mb={{ _: '2rem', small: '4rem', large: '5rem' }}>
      <Script id="rlxSmartClock" strategy="afterInteractive">
        {`(function(b,c,a,d,f,g,h,k,l,m,n){b[d]=b[d]||function(p){delete b[d];p.create(c.getElementById(f),[g,h,k,l,m,n])};var e=c.getElementsByTagName(a)[0];a=c.createElement(a);a.async=!0;a.src="//clock.rolex.com/smart-clock/static/js/invoker.js";e.parentNode.insertBefore(a,e)})(window,document,"script","rlxSmrtClck","rlxSmartClock","3749a28a87220925506fa122a876c80e","${
          language === 'nl' ? 'nl' : 'en'
        }","https://www.gassan.com/rolex","richright","light","gold");`}
      </Script>
      <div id="rlxSmartClock"></div>
    </Box>
  )
}

export default HomepageRolexPosBanner
