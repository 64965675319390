// @ts-strict-ignore
import { LeadStore, useArticleQuery } from '@generated'
import { FC, useState } from 'react'
import { CbProductPurchase as CbProductPurchaseType } from '@lib/kontent/models/CbProductPurchase'
import { initializeContactForm } from '@lib/initialize-contact-form'
import { useRouter } from 'next/router'
import { Modal } from '@components/Modal'
import ContactForm from '@components/_contact/ContactForm'
import pinterestTracking from '@lib/pinterest-tracking'
import { useTranslation } from 'next-i18next'
import { useInView } from 'react-intersection-observer'
import ProductDetailSection from '@components/_product-detail/ProductDetailSection/ProductDetailSection'
import Loading from '@components/_product-detail/ProductDetailSection/Loading'

const CbProductPurchase: FC<CbProductPurchaseType> = ({ id }) => {
  const router = useRouter()
  const language = router.locale || 'en'

  const [ref, hideMainCtaMobile] = useInView()
  const [isContactOpen, setIsContactOpen] = useState(false)
  const { t } = useTranslation('other')

  const [{ data }] = useArticleQuery({
    variables: { id: id.value },
  })

  if (!data) return <Loading />

  return (
    <>
      <ProductDetailSection
        article={data.article}
        onOpenContactModal={(type) => {
          setIsContactOpen(true)
          initializeContactForm(type, true)
        }}
        hideMainCtaMobile={hideMainCtaMobile}
        galleryVariant="default"
        language={language}
      />
      <div ref={ref}>
        <Modal
          open={isContactOpen}
          onOpenChange={(open) => setIsContactOpen(open)}
          title={t('contactUs')}
        >
          <ContactForm
            subject={`Vraag over ${data.article.brand} - ${data.article.id}`}
            leadStore={LeadStore.Gassan}
            isProductPage={true}
            onSubmitSuccess={(values) => {
              if (data.article.id) {
                pinterestTracking.lead({
                  lead_type:
                    values.type === 'APPOINTMENT' ? 'product-appointment' : 'product-question',
                  product_id: data.article.id,
                })
              }
              setIsContactOpen(false)
            }}
          />
        </Modal>
      </div>
    </>
  )
}

export default CbProductPurchase
