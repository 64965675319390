import { Image } from '@components/gassan-ui'
import { cn } from '@lib/cn'
import { HomepageWorldOfRolexBanner } from '@lib/kontent/models/HomepageWorldOfRolexBanner'
import { m } from 'framer-motion'
import { forwardRef } from 'react'
import { getKontentImage } from '@lib/kontent'
import { BannerContentDesktop } from './bannerContent/BannerContentDesktop'
import { BannerContentMobile } from './bannerContent/BannerContentMobile'

type WorldOfRolexBannerProps = HomepageWorldOfRolexBanner

const WorldOfRolexBanner = forwardRef<HTMLElement, WorldOfRolexBannerProps>((data) => {
  const { mobile_image, desktop_image, title } = data

  return (
    <section className="relative">
      <div
        className={cn(
          'relative mx-auto w-full max-w-[2560px] overflow-hidden',
          'before:content before:block before:pt-[122%] sm:before:pt-[60%] md:before:pt-[70%] lg:before:pt-[55%] xl:before:pt-[47%] xxl:before:pt-[725px]',
        )}
      >
        <m.div
          className={cn('absolute left-0 top-0 h-full w-full')}
          transition={{ duration: 0.5, ease: 'linear' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key={title.value}
        >
          <Image
            className="h-full"
            sources={[
              getKontentImage(mobile_image.value[0].url, { width: 768 }),
              getKontentImage(desktop_image.value[0].url, { width: 1024 }),
              getKontentImage(desktop_image.value[0].url, { width: 1440 }),
              getKontentImage(desktop_image.value[0].url, { width: 1920 }),
              getKontentImage(desktop_image.value[0].url, { width: 2560 }),
            ]}
            placeholder={getKontentImage(desktop_image.value[0].url, { width: 50 })}
            alt={desktop_image.value[0].description}
          />
        </m.div>
      </div>
      <BannerContentDesktop item={data} />
      <BannerContentMobile item={data} />
    </section>
  )
})

export default WorldOfRolexBanner
