import { Container, LoadingBar } from '@components/gassan-ui'
import { cn } from '@lib/cn'
import { motion } from 'framer-motion'
import { FC } from 'react'

const Loading: FC = () => {
  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
      className="p-12"
    >
      <Container>
        <div className={cn('flex w-full flex-col gap-4', 'lg:flex-row lg:gap-40')}>
          <div className={cn('hidden', 'lg:block lg:flex-[3]')}>
            <LoadingBar width="100%" height="100%" mb=".5rem" />
          </div>
          <div className={cn('block', 'lg:hidden')}>
            <LoadingBar width="100%" height="20rem" mb=".5rem" />
          </div>
          <div className={cn('flex flex-1 flex-col gap-8', 'lg:gap-16')}>
            <div className="flex flex-col gap-2">
              <LoadingBar width="100%" maxWidth="14rem" height="4rem" mb=".5rem" />
              <LoadingBar width="100%" maxWidth="7rem" height="1rem" mb=".5rem" />
              <LoadingBar width="100%" maxWidth="12rem" height="1rem" mb=".5rem" />
            </div>
            <div className="flex flex-col gap-2">
              <LoadingBar width="100%" height="4rem" mb=".5rem" />
              <LoadingBar width="100%" height="4rem" mb=".5rem" />
              <LoadingBar width="100%" maxWidth="7rem" height="1rem" mb=".5rem" />
              <LoadingBar width="100%" maxWidth="7rem" height="1rem" mb=".5rem" />
              <LoadingBar width="100%" maxWidth="7rem" height="1rem" mb=".5rem" />
            </div>
          </div>
        </div>
      </Container>
    </motion.div>
  )
}

export default Loading
