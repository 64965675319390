import { Button, Container, OldHeading } from '@gassan-ui'
import { cn } from '@lib/cn'
import { HomepageHeroItem } from '@lib/kontent/models/HomepageHeroItem'
import { HomepageWorldOfRolexBanner } from '@lib/kontent/models/HomepageWorldOfRolexBanner'
import { m } from 'framer-motion'
import { FC } from 'react'

type BannerContentMobileProps = {
  item: HomepageHeroItem | HomepageWorldOfRolexBanner
}

export const BannerContentMobile: FC<BannerContentMobileProps> = ({ item }) => {
  const isRolex =
    ('apply_brand_style' in item && item.apply_brand_style.value[0]?.codename === 'rolex') ||
    ('apply_rolex_style' in item && !!item.apply_rolex_style.value[0])

  const style = item.style.value[0]?.codename || 'dark'

  return (
    <>
      <m.div
        className="block lg:hidden"
        key={`mobile-${item.button_text.value}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.25, duration: 0.25, ease: 'easeInOut' }}
      >
        <Container
          className={cn('flex flex-col items-center bg-white py-6 text-center', 'lg:hidden')}
        >
          {item.title.value && (
            <OldHeading variant="hXl" mb={{ _: '1rem', medium: '2rem' }}>
              {item.title.value}
            </OldHeading>
          )}
          <Button
            as="link"
            href={item.button_href.value}
            className="min-w-full sm:min-w-[auto]"
            variant={isRolex ? 'rolex' : style ? 'dark' : 'light'}
          >
            {item.button_text.value}
          </Button>
        </Container>
      </m.div>
    </>
  )
}
